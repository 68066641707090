<template>
  <div>
    <div style="margin: 0 20px">
      <p style="font-weight: 600; margin-bottom: 20px">完善患者信息</p>
      <div style="height: 76px;">
        <el-steps
          style="width: 300px;height: 76px;float: left;"
          :active="step">
          <el-step title="基线信息" :status="step === 2 ? 'wait':  'finish'"></el-step>
          <el-step title="植入信息" :status="step === 1 ? 'wait':  'finish'"></el-step>
        </el-steps>
        <el-button
          v-if="step === 1"
          size="small"
          type="primary"
          style="float: right;"
          @click="onNext">
          下一步
        </el-button>
        <el-button
          v-if="step === 2"
          size="small"
          type="primary"
          style="float: right;margin-left: 12px"
          @click="onSave"
          >
          保存
        </el-button>
        <el-button
          v-if="step === 2"
          size="small"
          style="float: right"
          @click="onPrevious">
          上一步
        </el-button>
      </div>
    </div>
    <el-divider></el-divider>
    <div
      v-if="step === 1"
      style="margin: 0 20px; width:500px;">
      <el-form :model="patientInfoForm" :rules="patientInfoRules" ref="rulePatientInfoForm" label-position="left" label-width="80px" class="demo-ruleForm">
        <el-form-item label="姓名" prop="name">
          <el-input
            v-model="patientInfoForm.name"
            size="small"
            class="fix-form__width"
            placeholder="请输入患者姓名">
          </el-input>
        </el-form-item>
        <el-form-item label="性别" prop="gender">
            <el-select
              v-model="patientInfoForm.gender"
              size="small"
              placeholder="请选择性别"
              class="fix-form__width">
              <el-option
                v-for="item in genderEnums"
                :key="item.code"
                :label="item.text"
                :value="item.code">
              </el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="身份证号" prop="certificateNo">
            <el-input
              v-model="patientInfoForm.certificateNo"
              size="small"
              autocomplete="off"
              maxlength="18"
              class="fix-form__width"
              placeholder="请输入身份证号"
            ></el-input>
        </el-form-item>
        <el-form-item label="病历号" prop="recordNo">
            <el-input
              v-model="patientInfoForm.recordNo"
              size="small"
              placeholder="请输入病历号"
              autocomplete="off"
              class="fix-form__width"
            ></el-input>
        </el-form-item>
        <el-form-item label="常驻地" prop="">
          <el-cascader v-model="addressLabel" size="small" :options="regions" :show-all-levels="false" placeholder="请选择患者常驻地" style="width: 300px;"></el-cascader>
        </el-form-item>
        <el-form-item label="文化程度" prop="educationBackground">
          <el-select v-model="educationBackground" size="small" style="width: 300px;">
            <el-option label="请选择" value="0"></el-option>
            <el-option label="初中及以下" value="1"></el-option>
            <el-option label="高中" value="2"></el-option>
            <el-option label="大学" value="3"></el-option>
             <el-option label="研究生及以上" value="4"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="hasDiseases" label="基础疾病">
          <el-radio-group v-model="hasUnderlyingDiseases" @change="changeHasDiseases">
            <el-radio :label="0">无</el-radio>
            <el-radio :label="1">有</el-radio>
          </el-radio-group>
          <div class="diseases-box" v-if="hasUnderlyingDiseases === 1">
            <el-checkbox-group
              v-model="underlyingDiseases"
              @change="handleChangeUdrDis">
              <el-checkbox
                class="el-checkbox-width"
                v-for="item in underlyingDiseaseSelect" :label="item.code"
                :key="item.code">
                {{item.text}}
                <el-input
                  class="input-otherdiseases"
                  v-show="item.type === 2 && visableOtherDisInput"
                  v-model="otherBasicDiseases"
                  minlength="2"
                  maxlength="20"
                ></el-input>
                <br>
              </el-checkbox>
            </el-checkbox-group>
          </div>
        </el-form-item>
      </el-form>
    </div>
    <el-scrollbar
      v-if="step === 2"
      style="height: 100%">
      <div class="container-content">
        <div class="content-item">
          <p class="item-title">1、CIED植入信息</p>
            <el-form :model="implantInfo" :rules="implantInfoRules" ref="implantInfoRuleForm" label-width="80px">
              <el-row>
                  <el-form-item label="植入日期" prop="implantDate">
                    <el-date-picker
                      v-model="implantInfo.implantDate"
                      size="small"
                      class="fix-form__width"
                      type="date"
                      format="yyyy-MM-dd"
                      :picker-options="pickerOptions"
                      value-format="timestamp"
                      placeholder="请选择日期">
                    </el-date-picker>
                  </el-form-item>
                </el-row>
                <el-row>
                  <el-form-item label="植入医院">
                    <el-input v-model="implantInfo.implantHospital" size="small" class="fix-form__width"
                    placeholder="请输入医院名称"></el-input>
                  </el-form-item>
                </el-row>
              <el-row>
                <el-form-item label="术者" prop="operator">
                  <el-input v-model="implantInfo.operator" size="small" minlength="2" maxlength="20" class="fix-form__width" placeholder="请输入术者姓名"></el-input>
                </el-form-item>
              </el-row>
            </el-form>
        </div>
        <div class="content-item">
          <p class="item-title">2、CIED植入部位</p>
          <el-radio-group
          class="radio-group-one-level"
          v-model="implantPosition"
          @change="onChangeImplantPosition">
            <el-radio :label="1">左侧</el-radio>
            <el-radio :label="2">右侧</el-radio>
            <el-radio :label="3">
            其他
            <el-input
              :disabled="implantPosition !== 3"
              size="small"
              v-model="otherImplantSite"
              placeholder="请输入内容"
              style="width: 228px; margin-left: 4px;">
            </el-input>
            </el-radio>
          </el-radio-group>
        </div>
        <div class="content-item">
          <p class="item-title">3、适应症</p>
          <el-checkbox-group
            v-model="indications.indicationsSymptom" @change="changeAdaptation">
            <el-checkbox class="el-check-pinpai" :label="0">
              心动过缓
            </el-checkbox>
            <el-radio-group v-if="indications.indicationsSymptom.indexOf(0) !== -1" v-model="indications.bradycardiaRadio" class="adaptation_content--1">
              <el-radio :label="0">病态窦房结综合征</el-radio>
              <el-radio :label="1">房室传导阻滞</el-radio>
              <el-radio :label="2">房颤伴慢室率</el-radio>
            </el-radio-group>
            <el-checkbox class="el-check-pinpai" :label="1">
              心力衰竭
            </el-checkbox>
            <el-checkbox class="el-check-pinpai" :label="2">
              室性心律失常
            </el-checkbox>
            <el-radio-group @change="changeIndicationsSymptop" v-if="indications.indicationsSymptom.indexOf(2) !== -1" v-model="indications.arrhythmiaRadio" class="adaptation_content--1">
              <el-radio :label="0">室性心动过速</el-radio>
              <el-radio :label="1">心室颤动</el-radio>
              <el-radio :label="2">室性期前收缩</el-radio>
            </el-radio-group>
            <el-checkbox :label="3">
              其他
            </el-checkbox>
            <el-input
              :disabled="indications.indicationsSymptom.indexOf(3) === -1"
              size="small"
              v-model="indications.otherIndications"
              placeholder="请输入内容"
              style="width: 228px; margin-left: 4px;"
              minlength="2"
              maxlength="20"
              :onkeyup="
                (function () {
                  indications.otherIndications=indications.otherIndications.replace(/[^\u4e00-\u9fa5a-zA-Z0-9·]/ig,'')
                })()">
            </el-input>
          </el-checkbox-group>
        </div>
        <div class="content-item">
          <p class="item-title">4、CIED类型</p>
          <el-radio-group
          v-model="ciedType.ciedTypeRadio"
          @change="changeCiedType">
            <el-radio :label="0">单腔起搏器</el-radio>
            <el-radio :label="1">双腔起搏器</el-radio>
            <el-radio :label="2">单腔ICD</el-radio>
            <el-radio :label="3">双腔ICD</el-radio>
            <el-radio :label="4">CRT-P</el-radio>
            <el-radio :label="5">CRT-D</el-radio>
            <el-radio :label="6">
              其他
              <el-input
                :disabled="ciedType.ciedTypeRadio !== 6"
                v-model="ciedType.otherCiedType"
                size="small"
                placeholder="请输入内容"
                style="display: inline-block; width: 228px"
                :onkeyup="
                (function () {
                  ciedType.otherCiedType=ciedType.otherCiedType.replace(rules,'')
                })()"
              ></el-input>
            </el-radio>
          </el-radio-group>
        </div>
        <div class="content-item">
          <p class="item-title">5、CIED品牌</p>
          <el-radio-group
            class="ciedBrand-radio-group"
            v-model="ciedBrand"
            @change="changeCiedBrand">
            <el-radio v-for="(i, k) in brandDict" :key="k" :label="i.code">{{i.name}}</el-radio>
            <el-input v-if="ciedBrand === '5'" size="small" style="width: 200px; margin-right: 15px" v-model="otherCiedBrand" placeholder="其他品牌"></el-input>
          </el-radio-group>
        </div>
        <div class="content-item">
          <p class="item-title">6、CIED型号</p>
          <el-select size="small" v-if="ciedBrand && (ciedBrand !== '5')" filterable style="margin-right: 15px;" class="el-checkbox-input" allow-create v-model="ciedModel" :placeholder="brandPlaceholder">
            <el-option
              v-for="(m, c) in modelDick"
              :key="c"
              :label="m.name"
              :value="m.code"
            >
            </el-option>
            <el-option label="其他" value="0"></el-option>
          </el-select>
          <el-input v-if="ciedModel === '0' || ciedBrand === '5'" size="small" v-model="otherCiedModel" placeholder="请输入其他型号" ></el-input>
        </div>
        <div class="content-item">
          <p class="item-title">7、CIED序列号</p>
          <el-input v-if="ciedBrand" v-model="ciedSn" size="small" placeholder="请填写CIED序列号"></el-input>
        </div>
        <div class="content-item">
          <p class="item-title">8、导线类型及参数</p>
          <el-form
          :model="wireParam">
            <el-form-item>
              <el-checkbox
              v-model="wireParam.checkedAtrial"
              label="心房导线"
              @change="changeLead">
              </el-checkbox>
              <div class="lead-atrial" v-if="wireParam.checkedAtrial">
                <div class="atrial-title">
                  植入部位
                </div>
                <div class="atrial-content">
                  <el-radio-group  v-model="wireParam.atrialImplantPosition">
                    <el-radio :label="0">右心耳</el-radio>
                    <el-radio :label="1">右心房侧壁</el-radio>
                  </el-radio-group>
                </div>
                <br>
                <div class="atrial-title">导线型号</div>
                <div class="atrial-content">
                  <el-input class="atrial-input-one" size="small" placeholder="请输入导线型号" v-model="wireParam.atrialWireModel"></el-input>
                </div>
                <br>
                <div class="atrial-title">
                  起搏阈值
                </div>
                <div class="atrial-content">
                  <el-input size="small" class="atrial-input" placeholder="请输入内容" v-model="wireParam.atrialPacingThresholdV">
                  <i style="margin-right: 10px;" slot="suffix">V@</i></el-input>
                  <el-input size="small" class="atrial-input" placeholder="请输入内容" v-model="wireParam.atrialPacingThresholdMs">
                  <i style="margin-right: 10px;" slot="suffix">ms</i>
                  </el-input>
                </div>
                <br>
                <div class="atrial-title">
                  P波高度
                </div>
                <div class="atrial-content">
                  <el-input size="small" class="atrial-input-one" placeholder="请输入内容" v-model="wireParam.atrialPWaveAltitude">
                  <i style="margin-right: 10px;" slot="suffix">mV</i>
                  </el-input>
                </div>
                <br>
                <div class="atrial-title">
                  阻抗
                </div>
                <div class="atrial-content">
                  <el-input size="small" class="atrial-input-one" placeholder="请输入内容" v-model="wireParam.atrialImpedance">
                  <i style="margin-right: 10px;" slot="suffix">Ω</i>
                  </el-input>
                </div>
              </div>
              <el-checkbox v-model="wireParam.checkedRightVentricular" class="el-check-pinpai" label="右心室导线" @change="changeLead"></el-checkbox>
              <div class="lead-right" v-if="wireParam.checkedRightVentricular">
              <div class="atrial-title">植入部位</div>
              <div class="atrial-content">
                <el-radio-group v-model="wireParam.rightImplantPosition">
                  <el-radio :label="0">右室心尖部</el-radio>
                  <el-radio :label="1">右室间隔部</el-radio>
                  <el-radio :label="2">希氏束区域</el-radio>
                  <el-radio :label="3">左束支区域</el-radio>
                </el-radio-group>
              </div>
              <br>
              <div class="atrial-title">导线型号</div>
              <div class="atrial-content">
                <el-input class="atrial-input-one" size="small" placeholder="请输入导线型号" v-model="wireParam.rightWireModel"></el-input>
              </div>
              <br>
              <div class="atrial-title">起搏阈值</div>
              <div class="atrial-content">
                <el-input size="small" class="atrial-input" placeholder="请输入内容" v-model="wireParam.rightPacingThresholdV">
                <i style="margin-right: 10px;" slot="suffix">V@</i>
                </el-input>
                <el-input size="small" class="atrial-input" placeholder="请输入内容" v-model="wireParam.rightPacingThresholdMs">
                <i style="margin-right: 10px;" slot="suffix">ms</i>
                </el-input>
              </div>
              <br>
              <div class="atrial-title">R波高度</div>
              <div class="atrial-content">
                <el-input size="small" class="atrial-input-one" placeholder="请输入内容" v-model="wireParam.rightRWaveAltitude">
                <i style="margin-right: 10px;" slot="suffix">mV</i>
                </el-input>
              </div>
              <br>
              <div class="atrial-title">阻抗</div>
              <div class="atrial-content">
                <el-input size="small" class="atrial-input-one" placeholder="请输入内容" v-model="wireParam.rightImpedance">
                <i style="margin-right: 10px;" slot="suffix">Ω</i>
                </el-input>
              </div>
              <br>
              <div class="atrial-title-long">高压除颤阻抗</div>
              <div class="atrial-content">
                <el-input size="small" class="atrial-input-one-long" placeholder="请输入内容" v-model="wireParam.rightHighVoltageImpedance">
                <i style="margin-right: 10px;" slot="suffix">Ω</i>
                </el-input>
              </div>
              </div>
              <el-checkbox v-model="wireParam.checkedLeftVentricular" class="el-check-pinpai" label="左心室导线" @change="changeLead"></el-checkbox>
              <div class="lead-left" v-if="wireParam.checkedLeftVentricular">
                <div class="atrial-title">植入部位</div>
                <div class="atrial-content">
                  <el-radio-group v-model="wireParam.leftImplantPosition" @change="changeLeadSite">
                    <el-radio :label="0">侧后静脉</el-radio>
                    <el-radio :label="1">侧静脉</el-radio>
                    <el-radio :label="2">其他</el-radio>
                    <el-input
                      size="mini"
                      placeholder="请输入内容"
                      v-show="wireParam.leftImplantPosition === 2"
                      v-model="wireParam.leftOtherPosition"
                      minlength="2"
                      maxlength="20">
                    </el-input>
                  </el-radio-group>
                </div>
                <br>
                <div class="atrial-title">导线型号</div>
                <div class="atrial-content">
                  <el-input class="atrial-input-one" size="small" placeholder="请输入导线型号" v-model="wireParam.leftWireModel"></el-input>
                </div>
                <br>
                <div class="atrial-title">起搏阈值</div>
                <div class="atrial-content">
                  <el-input
                  size="small"
                  class="atrial-input"
                  placeholder="请输入内容"
                  v-model="wireParam.leftPacingThresholdV"
                  >
                  <i style="margin-right: 10px;" slot="suffix">V@</i>
                  </el-input>
                  <el-input
                  size="small"
                  class="atrial-input"
                  placeholder="请输入内容"
                  v-model="wireParam.leftPacingThresholdMs">
                  <i style="margin-right: 10px;" slot="suffix">ms</i>
                  </el-input>
                </div>
                <br>
                <div class="atrial-title">R波高度</div>
                <div class="atrial-content">
                  <el-input class="atrial-input-one" size="small" placeholder="请输入内容" v-model="wireParam.leftRWaveAltitude">
                  <i style="margin-right: 10px;" slot="suffix">mV</i>
                  </el-input>
                </div>
                <br>
                <div class="atrial-title">阻抗</div>
                <div class="atrial-content">
                  <el-input class="atrial-input-one" size="small" placeholder="请输入内容" v-model="wireParam.leftImpedance">
                  <i style="margin-right: 10px;" slot="suffix">Ω</i>
                  </el-input>
                </div>
              </div>
            </el-form-item>
          </el-form>
        </div>
        <div class="content-item">
          <p class="item-title">9、起搏器参数设置</p>
          <div>起搏模式</div>
          <el-radio-group v-model="pacemakerQuery.pacemakerModel" class="subPart adaptation_content--1">
            <el-radio :label="1">AAI(R)</el-radio>
            <el-radio :label="2">VVI(R)</el-radio>
            <el-radio :label="3">DDD(R)</el-radio>
          </el-radio-group>
          <div>基线频率</div>
          <div>
            <el-input
            size="small"
            maxlength="2"
            v-model="pacemakerQuery.bareLineHeartRate"
            class="atrial-input-one"
            placeholder="请输入内容"
            :onkeyup="
              (function () {
                pacemakerQuery.bareLineHeartRate=pacemakerQuery.bareLineHeartRate.replace(/[^\d]/g,'')
              })()"
            >
            <i
            style="margin-right: 10px; line-height: 32px;" slot="suffix">bpm</i>
            </el-input>
          </div>
          <span >
          </span>
        </div>
        <div class="content-item">
          <p class="item-title">10、围术期不良事件</p>
          <el-radio-group
            class="radio-group-one-level"
            v-model="adverseEvent.hasEvent"
            @change="changeEvent">
              <el-radio :label="0">无</el-radio>
              <el-radio :label="1">有</el-radio>
          </el-radio-group>
          <el-checkbox-group
            v-if="adverseEvent.hasEvent === 1"
            v-model="adverseEvent.events"
            class="adaptation_content--1 events"
            @change="changeEventCheckBox">
            <el-checkbox :label="1" name="type">气胸</el-checkbox>
            <el-checkbox :label="2" name="type">血胸</el-checkbox>
            <el-checkbox :label="3" name="type">血气胸</el-checkbox>
            <el-checkbox :label="4" name="type">导线穿孔</el-checkbox>
            <el-checkbox :label="5" name="type">导线脱落</el-checkbox>
            <el-checkbox :label="6" name="type">感染</el-checkbox>
            <el-checkbox :label="7" name="type">导线重置</el-checkbox>
            <el-checkbox :label="8" name="type">血肿</el-checkbox>
            <el-checkbox :label="9" name="type">其他</el-checkbox>
            <el-input
              v-if="adverseEvent.events.indexOf(9) !== -1"
              v-model="adverseEvent.otherEvent"
              size="small"
              style="width: 228px; margin-left: 4px"
              placeholder="请输入其他不良事件">
              </el-input>
          </el-checkbox-group>
        </div>
        <div style="height: 40px;"></div>
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
import { genderEnums } from '@/utils/enums'
import {
  getDictionary
} from '@/service/module/followup'
import { createPatient } from '@/service/module/addPatient'
import regions from '@/utils/region'
export default {
  components: {

  },
  data () {
    var checkCertificateNo = (rule, value, callback) => {
      var reg = /(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{2}[0-9Xx]$)/
      if (!value) {
        callback(new Error('请输入身份证号码'))
      }
      if (reg.test(value) === false) {
        callback(new Error('请输入正确的身份证号码'))
        return false
      } else {
        callback()
        return true
      }
    }
    return {
      genderEnums,
      regions,
      addressLabel: [],
      pickerOptions: {
        disabledDate (time) {
          return time.getTime() > Date.now()
        }
      },
      step: 1,
      patientInfoForm: {},
      patientInfoRules: {
        name: [
          { required: true, message: '请输入患者姓名', trigger: 'blur' }
          // { min: 2, max: 20, message: '长度在 2 到 20 个字符', trigger: 'blur' },
          // { pattern: /^[\u4E00-\u9FA5A-Za-z·]+$/, message: '请输入中文/英文/·' }
        ],
        gender: [
          { required: true, message: '请选择性别', trigger: 'change' }
        ],
        certificateNo: [
          { required: true, message: '请输入身份证号' },
          { validator: checkCertificateNo, trigger: 'blur' }
        ],
        recordNo: [
          { required: true, message: '请输入病历号', trigger: 'blur' }
        ]
      },
      educationBackground: '',
      // 品牌序列号
      ciedSn: '',
      // 1、植入信息
      implantInfo: {
        implantDate: '',
        implantDoctor: '',
        implantHospital: ''
      },
      // 2、植入部位
      implantPosition: -1,
      // 3、适应证
      indications: {
        indicationsSymptom: [],
        bradycardiaRadio: null,
        otherIndications: '',
        arrhythmiaRadio: null
      },
      // 是否有基础疾病
      hasUnderlyingDiseases: -1,
      visableOtherDisInput: false,

      // 4、类型
      ciedType: {
        ciedTypeRadio: -1,
        otherCiedType: ''
      },
      // 5、品牌
      ciedBrand: '',
      ciedModel: '',
      otherCiedBrand: '',
      otherCiedModel: '',
      brandDick: [],
      modelDick: [],
      // 6、导线
      wireParam: {
        checkedAtrial: false,
        checkedLeftVentricular: false,
        checkedRightVentricular: false,
        // 心房导线参数
        atrialImplantPosition: -1,
        atrialWireModel: '',
        atrialPacingThresholdV: '',
        atrialPacingThresholdMs: '',
        atrialPWaveAltitude: '',
        atrialImpedance: '',
        // 右心室导线参数
        rightImplantPosition: -1,
        rightWireModel: '',
        rightPacingThresholdV: '',
        rightPacingThresholdMs: '',
        rightRWaveAltitude: '',
        rightHighVoltageImpedance: '',
        rightImpedance: '',
        // 左心室导线参数
        leftOtherPosition: '',
        leftImplantPosition: -1,
        leftWireModel: '',
        leftPacingThresholdV: '',
        leftPacingThresholdMs: '',
        leftRWaveAltitude: '',
        leftImpedance: ''
      },
      // 7、起搏器参数
      pacemakerQuery: {
        checkedPacemakerModel: false,
        bareLineHeartRate: '',
        checkedBareLineHeartRate: false,
        pacemakerModel: 0
      },
      // 8、不良事件
      adverseEvent: {
        hasEvent: -1,
        events: [],
        otherEvent: ''
      },
      otherImplantSite: '', // 其他
      ruleForm: { // 患者信息表单信息
      },
      implantInfoRules: { // 植入信息表单校验
        implantDate: [
          { required: true, message: '请选择日期', trigger: 'blur' }
        ],
        operator: [
          { required: true, message: '请输入术者姓名', trigger: 'blur' },
          { min: 2, max: 20, message: '长度在 2 到 20 个字符', trigger: 'blur' },
          { pattern: /^[\u4E00-\u9FA5A-Za-z·]+$/, message: '请输入中文/英文/·' }
        ]
      },
      underlyingDiseaseSelect: [],
      underlyingDiseases: [],
      otherBasicDiseases: '' // 基础疾病其他文案

    }
  },
  created () {
    this.getBaselineInfo()
  },
  computed: {
    brandPlaceholder () {
      let placeholder = ''
      switch (this.ciedBrand) {
        case '1': placeholder = '请选择美敦力型号'
          break
        case '2': placeholder = '请选择雅培型号'
          break
        case '3': placeholder = '请选择百多力型号'
          break
        case '4': placeholder = '请选择波科型号'
          break
        default: placeholder = ''
          break
      }
      return placeholder
    }
  },
  methods: {
    async getBaselineInfo () {
      try {
        // const res = await getBaselineInfo(this.patientId)
        const resDictionary = await getDictionary('underlyingDisease')
        this.underlyingDiseaseSelect = resDictionary.data
        const resCiedBrand = await getDictionary('ciedBrand')
        this.brandDict = resCiedBrand.data
      } catch (e) {
        console.log('getAwaitFollowUserInfo-err:', e)
      }
    },
    onNext () {
      this.$refs.rulePatientInfoForm.validate((valid) => {
        if (valid) {
          this.step = 2
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    onPrevious () {
      this.step = 1
    },
    changeCiedType (val) {
      if (val !== 6) {
        this.ciedType.otherCiedType = ''
      }
    },
    changeQuery () {
      if (!this.pacemakerQuery.checkedPacemakerModel) {
        this.pacemakerQuery.model = 0
        this.pacemakerQuery.pacemakerModel = 0
      }
      if (!this.pacemakerQuery.checkedBareLineHeartRate) {
        this.pacemakerQuery.bareLineHeartRate = ''
      }
    },
    changeAdaptation (val) {
      if (val.indexOf(0) === -1) {
        this.indications.bradycardiaRadio = null
      }
      if (val.indexOf(3) === -1) {
        this.indications.otherIndications = ''
      }
      if (val.indexOf(2) === -1) {
        this.indications.arrhythmiaRadio = null
      }
    },
    changeIndicationsSymptop () {
      this.$forceUpdate()
    },
    changeEvent () {
      if (this.adverseEvent.hasEvent !== 1) {
        this.adverseEvent.events = []
        this.adverseEvent.otherEvent = ''
      }
    },
    changeEventCheckBox (val) {
      if (val.indexOf(9) === -1) {
        this.adverseEvent.otherEvent = ''
      }
    },
    changeLead () {
      if (!this.wireParam.checkedAtrial) {
        this.wireParam.atrialImplantPosition = -1
        this.wireParam.atrialWireModel = ''
        this.wireParam.atrialPacingThresholdV = ''
        this.wireParam.atrialPacingThresholdMs = ''
        this.wireParam.atrialPWaveAltitude = ''
        this.wireParam.atrialImpedance = ''
      }
      if (!this.wireParam.checkedRightVentricular) {
        this.wireParam.rightImplantPosition = -1
        this.wireParam.rightWireModel = ''
        this.wireParam.rightPacingThresholdV = ''
        this.wireParam.rightPacingThresholdMs = ''
        this.wireParam.rightRWaveAltitude = ''
        this.wireParam.rightHighVoltageImpedance = ''
        this.wireParam.rightImpedance = ''
      }
      if (!this.wireParam.checkedLeftVentricular) {
        this.wireParam.leftOtherPosition = ''
        this.wireParam.leftImplantPosition = -1
        this.wireParam.leftWireModel = ''
        this.wireParam.leftPacingThresholdV = ''
        this.wireParam.leftPacingThresholdMs = ''
        this.wireParam.leftRWaveAltitude = ''
        this.wireParam.leftImpedance = ''
      }
    },
    changeLeadSite (val) {
      if (val !== 2) {
        this.wireParam.leftOtherPosition = ''
      }
    },
    onChangeImplantPosition (e) {
      if (e !== 3 && this.otherImplantSite) {
        this.otherImplantSite = ''
      }
    },
    changeHasDiseases (val) {
      if (val !== 1) {
        this.underlyingDiseases = []
        this.visableOtherDisInput = false
        this.otherBasicDiseases = ''
        console.log(this.patientInfoForm)
      }
    },
    changeCiedBrand (val) {
      this.ciedBrand = val
      if (this.ciedBrand !== '5') {
        const curOpt = this.brandDict.find(i => i.code === val)
        console.log(curOpt)
        this.modelDick = curOpt.models
        this.otherCiedBrand = ''
        this.ciedModel = ''
      } else {
        this.ciedModel = '0'
      }
      this.otherCiedModel = ''
    },
    handleChangeUdrDis (list) {
      console.log(list)
      this.visableOtherDisInput = false
      if (list.indexOf(4) !== -1) {
        this.visableOtherDisInput = true
      } else {
        this.visableOtherDisInput = false
        this.otherBasicDiseases = ''
      }
    },
    onSave () {
      this.$refs.implantInfoRuleForm.validate((valid) => {
        if (valid) {
          if (this.addressLabel.length > 0) {
            this.patientInfoForm.province = this.addressLabel[0]
            this.patientInfoForm.city = this.addressLabel[1]
            this.patientInfoForm.district = this.addressLabel[2]
          }

          this.patientInfoForm.underlyingDiseases = this.underlyingDiseases
          this.patientInfoForm.otherDiseases = this.otherBasicDiseases
          createPatient({
            ...this.patientInfoForm,
            ...this.implantInfo,
            adaptationDisease: this.indications,
            ciedBrand: this.ciedBrand,
            ciedModel: this.ciedModel,
            otherCiedBrand: this.otherCiedBrand,
            otherCiedModel: this.otherCiedModel,
            leadParam: this.wireParam,
            ciedImplantSite: this.implantPosition,
            ciedKind: this.ciedType,
            pacemakerParam: this.pacemakerQuery,
            adverseEvent: this.adverseEvent,
            ciedSn: this.ciedSn
          }).then(res => {
            if (res.code === 200) {
              this.$message.success('保存成功')
              this.$router.push({
                name: 'EnterPatient'
              })
            } else if (res.code === 10100) {
              this.$confirm('患者身份信息已存在，请核对后重试', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
              })
            } else if (res.code === 400) {
              this.$message.error(res.message)
            }
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.el-divider--horizontal {
 margin-top: 0px;
}
.fix-form__width {
  width: 300px;
}
.container-content {
  margin-top: 4px;
    .content-item {
      margin: 24px 0 0 24px;
      .item-title {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: #131414;
        margin-bottom: 8px;
      }
    }
    .el-input, .el-select {
      width: 213px;
    }
    .el-radio {
      height: 40px;
      line-height: 40px;
      margin-bottom: 8px;
    }
    .el-form {
      color: #606266;
      .el-form-item {
        margin-bottom: 16px;
      }
    }
    .adaptation_content--1 {
      display: flex;
      align-items: center;
      ::v-deep .el-radio {
        margin-bottom: 0;
      }
    }
    .lead-atrial, .lead-right, .lead-left {
      width: 100%;
    }
    .events {
      // width: 600px;
      display: flex;
      flex-wrap: wrap;
    }
    .atrial-title,.atrial-title-long {
      display: inline-block;
      color: #606266;
      font-size: 14px;
      margin-right: 8px;
      width: 56px;
    }
    .atrial-title-long {
      width: 85px;
    }
    .atrial-content{
      display: inline-block;
    }
    .atrial-input-one-long {
      width: 209px;
    }
    .atrial-input {
      width: 115px;
      margin-right:8px;
    }
    .atrial-input-one {
      width: 238px;
    }
    .el-radio[data-v-083ba69c] {
      margin: 0 24px 0 0;
      color: #606266;
      font-weight: 400;
    }
    .ciedBrand-radio-group {
      .el-radio {
        /*margin-bottom: 8px;*/
      }
    }
}
</style>
